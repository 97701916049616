<template>

  <Nav />
  <ModalContacto ref="modalcontacto" />
  <div class="home">
    <div id="inicio" class="main-hero">
      <div class="hero-title">
        Salud a la Puerta: Ofrezca sus servicios de Salud y Wellness a la casa
    </div>
      <div class="herosub">
        Aumenta tus ventas y reduce los costos de ofrecer servicios a domicilio
      </div>
      <div class="button-di">
        <button style="cursor: pointer; z-index: 8888;" v-on:click="agendar_reu()" class="btn-main">
          Agenda una reunión
        </button>
      </div>
      <div class="img-container">
        <img src="@/assets/cg/notebook1.png" alt="">
      </div>
    </div>








<Funcionalidades/>

<Testimonios />
<QuienesSomos />
<Faq/>
  <Footer />

  </div>
</template>

<script>
// @ is an alias to /src
import ModalContacto from '@/components/ModalContacto.vue'
import Testimonios from '@/components/Testimonios.vue'
import Funcionalidades from '@/components/Funcionalidades.vue'
import Faq from '@/components/Faq.vue'
import Planes from '@/components/Planes.vue'
import Footer from '@/components/Footer.vue'
import QuienesSomos from '@/components/QuienesSomos.vue'
import Nav from '@/components/Nav.vue'


export default {
  name: 'HomeView',
  components: {
    Nav,
    Testimonios,
    ModalContacto,
    Funcionalidades,
    Footer,
    Faq,
    Planes,
    QuienesSomos,
  },
  methods:{
    agendar_reu(){
      this.$refs.modalcontacto.show()
    }
  }
  
}

</script>

<style scoped>

.icon-cont{

}

h3 {
  color: #333; /* Adjust the color as needed */
  margin-bottom: 10px;
}

p {
  color: #666; /* Adjust the color as needed */
  line-height: 1.5;
}

.btn-main{
  padding: 15px 20px;
  cursor: pointer;
  
}
.button-di{
  padding: 20px 0px;
}
.herosub{
  margin: 0 auto;
  margin-top: 30px;
  color: #6f6b7d;
  max-width: 300px;


  line-height: 25px;

}

.img-container img{
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  top:0px;

  max-width: 100%;

}

.img-container{
  position: relative;
}
.main-hero{
  position: relative;
  padding-top:150px;
  /* max-height: 400px; */
  height: 550px;
  background: linear-gradient(to right, #1485e710 0%, #69beff21 100%);

  border-radius: 0px 0px 40px 40px;
  width: 100%;
  background-color: ;
}
</style>
