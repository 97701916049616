<template>
<div id="opiniones" class="main-bg bg-blue">
  <div class="container">
  <div class="title-column">
    <div class="testimonial-header">
      <div class="label">Opiniones Reales</div>
      <h2>¿Qué dicen nuestro clientes?</h2>
      <p>Revisa que han dicho nuestros clientes por su experiencia.</p>
      <div class="arrows">
      <button v-on:click="moveToPrevSlide()" id="prev">❮</button>
      <button  v-on:click="moveToNextSlide()" id="next">❯</button>
    </div>
    </div>
    

  </div>
  <div class="carousel-column">
    <div class="testimonial-carousel">
      <div class="testimonial-slide">
        <blockquote>
          "Con Clinic Go automatizamos el  proceso manual de agendar visitas a domicilio, lo cual nos trajo como beneficio optimizar nuestros recursos y mejorar la experiencia de nuestros usuarios, fidelizándolos con nuestra marca como prestador de salud visual. "
        </blockquote>
        <p class="author">Karin Saez - Reley</p>
      </div>
      <div class="testimonial-slide">
        <blockquote>
          "Andinno es una startup de base tecnológica con aplicación bioclínica, y Clinic Go nos ha permitido ofrecer nuestro innovador servicio de manera rápida y con una eficiente y grata experiencia para nuestros usuarios. También nos genera eficiencia y ahorros al poder automatizar el servicio y contar con un modelo que integra tecnología, personal en terreno y traslado de muestras a nuestro laboratorio."
        </blockquote>
        <p class="author">Paula Egaña, CFO.</p>
      </div>
<!--       <div class="testimonial-slide">
        <blockquote>
          "Opiniones random 3"
        </blockquote>
        <p class="author">- Federico</p>
      </div> -->
      
      <!-- More slides here -->
    </div>

  </div>
</div>

</div>

<div class="bg-blue main-2">
  <div class="main-2-within">
    <img src="@/assets/cg/logos/im.png" alt="">
  <img src="@/assets/cg/logos/rego.png" alt="">
  <img src="@/assets/cg/logos/clini.png" alt="">
  <img src="@/assets/cg/logos/neomedica.png" alt="">
  <img src="@/assets/cg/logos/bionet-logo.svg" alt="">
  <img src="@/assets/cg/logos/reley.png" alt="">
  <img src="@/assets/cg/logos/willbloom.png" alt="">
  <img src="@/assets/cg/logos/cardiopro.png" alt="">

  </div>

</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      currentSlide:0,
      totalSlides:0,
    }
  },
  mounted(){
    const slides = document.querySelectorAll('.testimonial-slide');
    this.totalSlides = slides.length

},
  methods:{
      updateSlidePosition() {
        const slides = document.querySelectorAll('.testimonial-slide');
        console.log(slides)
        for (let slide of slides) {
          slide.style.transform = 'translateX(-' + this.currentSlide * 100 + '%)';
        }
      },

      moveToNextSlide() {
        if (this.currentSlide === this.totalSlides - 1) {
          this.currentSlide = 0;
        } else {
          this.currentSlide++;
        }
        this.updateSlidePosition();
      },

    moveToPrevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides - 1;
      } else {
        this.currentSlide--;
      }
      this.updateSlidePosition();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.main-2 img {
  max-height: 100px;

  max-width: Calc(12.5% - 10px);
  filter: grayscale(100%); /* Makes the image fully greyscale */
  transition: filter 0.5s ease; /* Smooth transition for the filter property */

}

.main-2 img:hover {
  filter: grayscale(0%); /* Removes the greyscale filter on hover */
}
.main-2{

  border-top: 1px solid rgb(226, 226, 226);


}
.main-2-within{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}
.label{
  margin-bottom: 20px;
}
.bg-blue{
  background-color: #1485e709;
}
.main-bg{

  border-radius: 50px 50px 0px 0px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  margin: auto;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
}

.title-column {
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding-right: 20px; /* Add some spacing between the columns */
}

.carousel-column {
  flex-basis: 70%; /* Carousel column takes up the remaining 70% */
  padding-left: 20px; /* Add some spacing between the columns */
}

.testimonial-header h2,
.testimonial-header p {
  margin: 0; /* Remove default margins */
  padding: 0;
  text-align: left;
}
.testimonial-header h2{
  margin-bottom: 10px;
}

.testimonial-carousel {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  max-width: Calc(100vw - 100px); /* Enables smooth scrolling */
}

.testimonial-slide {
  flex: 0 0 100%; /* Each slide takes full width of the container */
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  padding: 0px;
  box-sizing: border-box;
  background: #fff;
  transition: transform 0.5s ease; /* Smooth transition for the transform property */
}

.arrows {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
}

@media only screen and (max-width: 900px) {
  .title-column {

  padding: 0px 10px; /* Add some spacing between the columns */
}
  .testimonial-header h2,
.testimonial-header p {

  text-align: center;
}
  .carousel-column {
  flex-basis: 100%;
  padding: 20px;
  margin: 0 auto;
  max-width: calc(100vw - 40px); /* Add some spacing between the columns */
}
  .container{
    flex-wrap: wrap;
  }
  
  .title-column {
    width: 100%;
    padding-bottom: 20px;

}
  .main-2 img {


  max-width: Calc(25% - 10px);


}
}
@media only screen and (max-width: 500px) {
  .main-2 img {


  max-width: Calc(50% - 10px);


}
.main-2{

padding: 0px 10px;


}
}
/* Add media queries for responsiveness if necessary */
</style>
