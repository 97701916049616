<template>
<div class="main-bg bg-blue">
  <div class="label">Planes de pago</div>
  <div class="titulo">Planes diseñados para <b class="hero-title">tus necesidades</b></div>
  <div class="subtitulo">

    
Todos los planes incluyen más de 40 herramientas y funciones avanzadas para potenciar tu producto.
Elige el mejor plan que se ajuste a tus necesidades.
  </div>
  <div class="pricing-table">
  <div class="plan">
    <h2>Básico</h2>
    <p>$140.000/mes</p>
    <ul>
      <li>Landing personalizado</li>
      <li>Administrador</li>
      <li>Reportería</li>
      <!-- More features -->
    </ul>
    <button class="btn-dis">Empezar</button>
  </div>

  <div class="plan">
    <h2>Full</h2>
    <p>$220.000/mes</p>
    <ul>
      <li>Todo lo del Básico</li>
      <li>Mapa profesionales</li>
      <li>App tomador</li>
      <!-- More features -->
    </ul>
    <button>Empezar</button>
  </div>

  <div class="plan">
    <h2>Plus</h2>
    <p>$370.000/mes</p>
    <ul>
      <li>Todo lo del Full</li>
      <li>Operación completa</li>
      <li>Servicio al cliente</li>
      <!-- More features -->
    </ul>
    <button class="btn-dis">Empezar</button>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      currentSlide:0,
      totalSlides:0,
    }
  },
  mounted(){
    const slides = document.querySelectorAll('.testimonial-slide');
    this.totalSlides = slides.length

},
  methods:{
      updateSlidePosition() {
        const slides = document.querySelectorAll('.testimonial-slide');
        console.log(slides)
        for (let slide of slides) {
          slide.style.transform = 'translateX(-' + this.currentSlide * 100 + '%)';
        }
      },

      moveToNextSlide() {
        if (this.currentSlide === this.totalSlides - 1) {
          this.currentSlide = 0;
        } else {
          this.currentSlide++;
        }
        this.updateSlidePosition();
      },

    moveToPrevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides - 1;
      } else {
        this.currentSlide--;
      }
      this.updateSlidePosition();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subtitulo{
  max-width: 550px;
  margin: 20px auto;
}
.main-bg{
  margin: 100px 0px;
}
.label{
  margin-bottom: 20px;
}

.main-bg .titulo{
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 6px;
}
  .pricing-table {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .plan {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    width: 300px; /* Adjust as per your requirement */
  }
  .plan h2 {
    background-color: #eee;
    padding: 10px;
    margin-top: 0;
  }
  .plan ul {
    list-style: none;
    padding: 0;
  }
  .plan ul li {
    padding: 10px;
    border-top: 1px solid #eee;
  }
  .plan button {
    background-color: #1485e7;
    color: white;
    border: none;
    width: 100%;
    padding: 10px 0px;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;

  }
  .btn-dis{
    background-color: #1485e73a !important;
    color: #1485e7 !important;
  }

  @media only screen and (max-width: 700px) {
    .pricing-table {
      flex-wrap: wrap;
  }
  .plan{
    width: 100%;
  }
}
/* Add media queries for responsiveness if necessary */
</style>
