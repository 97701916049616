<template>

<div id="funcionalidades" class="funcionalidades">
  <div class="spacer"></div>
      <div class="label">Funcionalidades Útiles</div>
      <div class="titulo">
        <b class="hero-title">Todo lo que necesitas</b> para atención domililiaria
      </div>
      <div class="subtitulo">
        Incluyendo sistema sistema informático automatizado, gestión logistica, y servicio de atención al cliente
      </div>
      <section class="features-section">
  <div class="feature">
    <div class="icon-container">
     <IconCalendarMonth class="icons"  />
     
    </div>
    <h3>Agenda Fácil</h3>
    <p>
      Sistema de citas intuitivo para reservas de servicios y prestaciones.
    </p>
  </div>
  <div class="feature">
    <div class="icon-container">
 <!--    <IconRobotFace class="icons"  /> -->
    <img  class="icons" src="@/assets/svg/icons/route.svg" alt="">
     
    </div>
    <h3>Optimizamos uso  de recursos</h3>
    <p>Automatización de informes, asignación de profesionales y seguimiento de muestras.</p>
  </div>
  <div class="feature">
    <div class="icon-container">

      <IconRotate class="icons"  />
    </div>
    <h3>Actualización Continua</h3>
    <p>Mantenimiento y mejora continua del software para garantizar la máxima eficiencia y seguridad en la gestión.</p>
  </div>



    <div class="feature">
    <div class="icon-container">
      <!-- Placeholder for the icon -->
      <IconUser class="icons"  />
    </div>
    <h3>Panel de Administración </h3>
    <p>Un panel de control avanzado para monitorear órdenes y ubicación de los tomadores de muestra en tiempo real.</p>
  </div>
  <div class="feature">
    <div class="icon-container">
      <!-- Placeholder for the icon -->
      <IconMap class="icons"  />
    </div>
    <h3>Expansión Geográfica</h3>
    <p>Servicios a domicilio para aumentar la base de clientes.</p>
  </div>
  <div class="feature">
    <div class="icon-container">
      <!-- Placeholder for the icon -->
   <!--    <IconSparkles class="icons"  /> -->
      <img  class="icons" src="@/assets/svg/icons/clap.svg" alt="">
    </div>
    <h3>Calidad Asegurada</h3>
    <p>Profesionales capacitados y tecnología avanzada para la toma de muestras y otros servicios.</p>
  </div>
  <!-- Repeat for each feature -->
</section>
    </div>
</template>

<script>

import { IconUser } from '@tabler/icons-vue';
import { IconCalendarMonth } from '@tabler/icons-vue';
import { IconRotate } from '@tabler/icons-vue';
import { IconRobotFace } from '@tabler/icons-vue';
import { IconMap } from '@tabler/icons-vue';
import { IconSparkles } from '@tabler/icons-vue';

export default {
  name: 'HelloWorld',
  components: {

    IconUser,
    IconCalendarMonth,
    IconRotate,
    IconRobotFace,
    IconMap,
    IconSparkles,

  },
  props: {
    msg: String
  },
  data(){
    return {
      currentSlide:0,
      totalSlides:0,
    }
  },
  mounted(){
    const slides = document.querySelectorAll('.testimonial-slide');
    this.totalSlides = slides.length

},
  methods:{
      updateSlidePosition() {
        const slides = document.querySelectorAll('.testimonial-slide');
        console.log(slides)
        for (let slide of slides) {
          slide.style.transform = 'translateX(-' + this.currentSlide * 100 + '%)';
        }
      },

      moveToNextSlide() {
        if (this.currentSlide === this.totalSlides - 1) {
          this.currentSlide = 0;
        } else {
          this.currentSlide++;
        }
        this.updateSlidePosition();
      },

    moveToPrevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides - 1;
      } else {
        this.currentSlide--;
      }
      this.updateSlidePosition();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.funcionalidades{
  margin-top: Min(Calc(45vw - 100px), 400px);
}
.funcionalidades .titulo{
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 6px;
}
.features-section {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  /* justify-content: space-between;  *//* Distribute space between items */
  padding: 20px;
  background-color: #fff; /* Adjust the background color as needed */
  max-width: 1200px; /* Adjust the max width as needed */
  margin: auto;
}

.feature {
  /* Each item can grow */
  width: Calc(33.33333% - 40px); /* Subtract the total margin/padding from 100% divided by 3 */
  text-align: center;
  padding: 20px;
  box-sizing: border-box; /* Include padding in the width calculation */
  margin: 10px;
}

.icon-container {
  height: 60px; /* Adjust as needed */
  width: 60px; /* Adjust as needed */
  margin: auto;
  margin-bottom: 20px;
  background-color: #1485e742;
  color: #1486e7;
  border-radius: 100%;
  padding:5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-placeholder {
  width: 60px;
  height: 60px;
  background-color: #ddd; /* Placeholder color */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.icons {
  width: 50px;
  height: 50px;

  
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 700px) {
  .feature {


  width: Calc(50% - 40px); /* Subtract the total margin/padding from 100% divided by 3 */

}
}
@media only screen and (max-width: 420px) {
  .feature {


  width: Calc(100% - 40px); /* Subtract the total margin/padding from 100% divided by 3 */


}
.spacer{
  height: 70px;
}
}
</style>
