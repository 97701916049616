<template>
<div id="quienes" class="main-bg bg-blue">
  <div class="label">¿Quienes somos?</div>

  <h2>¿Qué es Clinic GO?</h2>
  <div class="subtitulo">

    
    <b class="hero-title" style="font-size: 16px; line-height: 16px;">Clinic Go</b> es una healthtech que tiene como propósito <b class="hero-title" style="font-size: 16px; line-height: 16px;">  acercar la salud y bienestar a las personas</b>, mejorando su calidad de vida y disminuir finalmente las brechas de atención que afectan a gran parte de la sociedad.
  
  
    <p>
      Para lograrlo, nuestro objetivo es convertirnos en el principal  <b class="hero-title" style="font-size: 16px; line-height: 16px;" >  socio estratégico de los prestadores de salud/bienestar </b> que quieran ofrecer sus servicios al lugar donde se encuentren sus pacientes/usuarios y en el momento que lo requieran.

    </p>
    <p>Otorgamos la oportunidad a los prestadores de  <b class="hero-title" style="font-size: 16px; line-height: 16px;" > incrementar sus ingresos y optimizar sus recursos</b> , mejorando el modelo de negocio actual, a través de la digitalización y automatización del proceso de atención de salud a domicilio y lograremos impactar finalmente en la experiencia y fidelización de sus pacientes.
</p>
  </div>

</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      currentSlide:0,
      totalSlides:0,
    }
  },
  mounted(){
    const slides = document.querySelectorAll('.testimonial-slide');
    this.totalSlides = slides.length

},
  methods:{
      updateSlidePosition() {
        const slides = document.querySelectorAll('.testimonial-slide');
        console.log(slides)
        for (let slide of slides) {
          slide.style.transform = 'translateX(-' + this.currentSlide * 100 + '%)';
        }
      },

      moveToNextSlide() {
        if (this.currentSlide === this.totalSlides - 1) {
          this.currentSlide = 0;
        } else {
          this.currentSlide++;
        }
        this.updateSlidePosition();
      },

    moveToPrevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides - 1;
      } else {
        this.currentSlide--;
      }
      this.updateSlidePosition();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subtitulo{
  max-width: 550px;
  margin: 20px auto;
}
.main-bg{
  margin: 100px 0px;
}
.label{
  margin-bottom: 20px;
}

.main-bg .titulo{
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 6px;
}
  .pricing-table {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .plan {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    width: 300px; /* Adjust as per your requirement */
  }
  .plan h2 {
    background-color: #eee;
    padding: 10px;
    margin-top: 0;
  }
  .plan ul {
    list-style: none;
    padding: 0;
  }
  .plan ul li {
    padding: 10px;
    border-top: 1px solid #eee;
  }
  .plan button {
    background-color: #1485e7;
    color: white;
    border: none;
    width: 100%;
    padding: 10px 0px;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;

  }
  .btn-dis{
    background-color: #1485e73a !important;
    color: #1485e7 !important;
  }

  @media only screen and (max-width: 700px) {
    .pricing-table {
      flex-wrap: wrap;
  }
  .plan{
    width: 100%;
  }
}
/* Add media queries for responsiveness if necessary */
</style>
