<template>
  <nav class="layout-navbar-fixed">
    <ModalContacto ref="modalcontacto" />
    <div class="navbar">
      <div class="logo"><img src="@/assets/cg/logo.png" alt="logo"></div>
      <div>
        <div style="display: inline-block;">
          <ul   class="nav-links">
          <li><a :class="{'hovered':currentSection == 'inicio'}" href="#inicio">Inicio</a></li>
          <li><a :class="{'hovered':currentSection == 'funcionalidades'}" href="#funcionalidades">Funcionalidades</a></li>
          <li><a :class="{'hovered':currentSection == 'opiniones'}" href="#opiniones">Testimonios</a></li>
          <li><a :class="{'hovered':currentSection == 'quienes'}" href="#quienes">¿Quienes somos?</a></li>
          <li><a :class="{'hovered':currentSection == 'faq'}" href="#faq">Preguntas frecuentes</a></li>
          <li><a class="btn-main" v-on:click="agendar_reu()">Contacto</a></li>
        </ul>
        </div>
<!-- 
        <button class="btn-main">
          Contactar ahora
        </button> -->
      </div>
      <div class="burger" @click="toggleNav">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
    </div>
  </nav>
</template>

<script>
import ModalContacto from '@/components/ModalContacto.vue'
export default {
  name: 'NavBar',
  components: {

    ModalContacto,
  },
  data() {
    return {
      currentSection: null,
      sectionIds: ['inicio', 'funcionalidades', 'faq', 'quienes', 'opiniones']
    };
  },
  methods: {
    agendar_reu(){
      this.$refs.modalcontacto.show()
    },
    toggleNav() {
      document.querySelector('.nav-links').classList.toggle('nav-active');
      // Optional: Add some animation to burger
      document.querySelector('.burger').classList.toggle('toggle');
    },
    handleScroll() {
      let closestSectionId = null;
      let smallestDifference = Infinity;
      
      this.sectionIds.forEach((sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const boundingRect = sectionElement.getBoundingClientRect();
        const difference = Math.abs(boundingRect.top);
        
        if (difference < smallestDifference) {
          smallestDifference = difference;
          closestSectionId = sectionId;
        }
      });

      this.currentSection = closestSectionId;
      console.log('Current section:', this.currentSection);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>

#opiniones{
  scroll-margin-top: 160px;
}
.hovered{
  background-color: #1486e7;
  color:white !important; 
}
/* style.css */
.btn-main {
  display: inline-block;
  margin-left: 20px;
  color: white !important;
  background: linear-gradient(90deg, #1486e7 0%, #5a4aff 47.92%, #69bfff 100%);
}

.logo img {
  max-height: 40px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: white;
  padding: 2px 15px;
  max-width: min(1200px, calc(100vw - 80px));
  margin: 0 auto;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
}

.layout-navbar-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 10px;
  padding: 0px 20px;
  max-width: 100vw;
  z-index: 10;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-links li {
  display: inline-block;
  margin-left: 10px;
}
/* .nav-links a:hover {
  background-color:  #1486e7;
  color: white;

} */

.nav-links a {
  color: #6f6b7d;
  text-decoration: none;
  font-size: 1rem;
  padding: 6px 10px;
  border-radius: 4px;
  transition: 0.4s;
}

.burger {
  display: none;
  cursor: pointer;
  z-index: 12;
}


.burger div {
  width: 25px;
  height: 3px;
  background-color: black; /* Changed to black for visibility */
  margin: 5px;
  transition: all 0.3s ease;
}

/* Responsive */
@media screen and (max-width: 900px) {
  .burger {
    display: block;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0px;
    height: 100vh;
    top: -30px;
    padding-top:70px;
    background-color: #333;
    display: none;
    width: 50%;
    z-index: 10;
  }

  .nav-links li {
    margin: 15px 0;
  }

  .nav-active {
    display: flex;
  }

  .btn-main {
    display: block;
    margin: 20px auto; /* Center button when nav is active */
  }

  .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .toggle .line2 {
    opacity: 0;
  }

  .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
</style>