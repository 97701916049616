<template>
<div id="faq" class="bg-blue">

  <div class="label">
    FAQ
  </div>
  <div class="wrapper">



<div class="righty" >

  <div class="titulo">Preguntas <b class="hero-title">frecuentes</b></div>
  <div class="subtitulo">
    
Navega a través de estas preguntas frecuentes para encontrar respuestas a las preguntas que se hacen comúnmente.
  </div>

  <div style="height:40px;"></div>
  <div v-for="d in datac" class="faq-container">
<div v-on:click="clickearPaso(d); d.viendo = !d.viendo" class="faq-title tit2"> {{d.pregunta}}</div>
<div :class="{'abierto':d.viendo}" class="faq-respuesta">  {{d.respuesta}}</div>

</div>
<div style="height:40px;"></div>
<div>

</div>
<div style="height:40px;"></div>

</div>


</div>

</div>

</template>

<script>


import { mapGetters, mapActions } from "vuex";
export default {
  name: 'Bajada',
  components: {



  },
  props: {
    
  },
  data(){
    return {
    showing:false,
    examenes_showing:[],
    datac:[
    {viendo: false, pregunta:'¿Cómo comprar en ClinicGo?', respuesta:'Compra en 3 simples pasos, primera selecciona tu servicio en el buscador, agrega la comuna, día y hora. Luego de eso completa tus datos personales y listo. Un profesional de salud se dirigirá a tu casa y tú lo podrás seguir a través de link que te enviaremos.'},
    {viendo: false, pregunta:'Documentación necesaria para agendar', respuesta:'En el caso que necesites reembolsar o pagar con tu Isapre o Fonasa tu servicio, debes tener tu orden médica. De lo contrario podrás agendar de manera particular.'},
    {viendo: false, pregunta:'¿Se puede modificar o cancelar una compra?', respuesta:'En todo momento podrás modificar o cancelar tu servicio, esto a través del link que te enviamos a tu correo.'},

    {viendo: false, pregunta:'¿Por qué elegir ClinicGo para realizar tu examen?', respuesta:'Clinicgo cuenta con profesionales de salud especializados en atención domiciliaria, siendo reconocido como el mejor prestador a tu casa. Además, contamos con diversos convenios empresas que permite tener una cobertura nacional.'},

    ],

    

    window: {
        width: 1200,
        height: 0,
      },

      

    }
  },
  methods:{
    
    clickearPaso(p){


    },
    show(ids){
      this.examenes_showing = ids;
      this.showing = true;
    },
    hide(){
      this.showing = false;
    },
  handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    },


  },
  mounted(){

  
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed:{
        ...mapGetters([
      "comuna",
      "comunas",
      'examenes',
      "examenes_agregados",
      "comunas_usadas",
      "costo_servicio",
      "costo_servicio_pendiente",

      'codigo',
      'valores_codigo'
    ]),

  },
  watch:{
  
  },
}
</script>



<style scoped>

.bg-blue{
  background-color: #1485e709;
}
.label{
  margin-top: 100px;
  text-align: center;
}
.titulo{
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 6px;
  
}
.subtitulo{
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
}
.mobile{
  display: none;
}

.tit1{
  font-weight: 600;
  color:#0084E4;
  font-size: 22px;

}
.tit2{

  font-size: 22px;
  color:#666666;

}

.faq-title{
  padding:30px 40px;

  text-align: left;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  border-radius: 20px;
  font-size: 20px;
}
.faq-container{
  border-bottom: 1px solid rgb(211, 211, 211);
  margin-bottom: 20px;

  background-color: white;
  


}
.faq-title:hover{
  color:#4a29d3;
}
.faq-respuesta{
  overflow: hidden;
  max-height: 0px;
  padding: 0px 50px;
  text-align: left;
 
  transition:0.3s;

}
.abierto{
  max-height:300px;
  padding:  0px 40px 20px 40px;
}


.lefty-txt-cont{

}
.im1{
  position: absolute;
  bottom:0px;
  left:50px;
  z-index: 2;
  max-height: 600px;

}
.im2{
  position: absolute;
  bottom:00px;
  left:-80px;
  max-height: 600px;
  z-index: 0;

}
.lefty{

  position:relative;
  display: inline-block;
min-width: 600px;
vertical-align: bottom;


  


}

.wrapper{
  position: relative;
  vertical-align: top;
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;



}

@media only screen and (max-width: 1230px) {
.mobile{
  display: inherit;
}
.im3{
  position: absolute;
  top:50%;
  max-width: 100%;
  left:0px;
  z-index: 0;
  height:500px;
  transform: translateY(-50%);
}
  .lefty{
    

  display: none;
}

.righty{
  position: relative !important;
  padding: 10px;

  display: inline-block;


 
  z-index: 1;

  vertical-align: top;
}
}



</style>
