<template>
<div>
<transition name="zoom-m">
   
  <div v-if="showing" id="modal-receta-m" >
    
  <div v-on:click="hide();" class="contenedor-icono">       
    <!-- <b-icon class="icon" icon="x"></b-icon> -->

    <img  class="icon" src="@/assets/svg/icons/x.svg" alt="">
    
  </div>


  <div class="main-m">

<div v-if="!loading" >
   <div v-if="estado == 0">
      <div class="subtitulo2"> </div>
    <div class="texto">

      <div style="margin: 0 auto;" class="contact-form-container">
        <form  id="contact-form">
            <h2>Formulario de Contacto</h2>
            <div class="form-row">
    <div class="form-group half">
        <label for="name">Nombre:</label>
        <input v-model="nombre" type="text" id="name" name="name" >
    </div>
    <div class="form-group half">
        <label  for="last-name">Apellido:</label>
        <input v-model="apellido" type="text" id="last-name" name="last-name" >
    </div>
</div>
<div class="form-row">

   <div class="form-group half">
                <label for="email">Correo Electrónico:</label>
                <input v-model="email" type="email" id="email" name="email" >
            </div>
            <div class="form-group half">
                <label for="company">Empresa:</label>
                <input v-model="empresa" type="text" id="company" name="company">
            </div>
</div>

            <div class="form-group">
                <label for="message">Mensaje:</label>
                <textarea v-model="mensaje" id="message" name="message" rows="4" required></textarea>
            </div>
       <!--      <button type="submit">Enviar</button> -->
            <button type="submit" v-on:click="send_contact()" class="btn-enviar">Enviar</button>
        </form>
    </div>
      <div></div>
    </div>
   </div>
   <div v-if="estado == 1">
      <h2>¡Se ha enviado el mensaje!</h2>
      <p>Estaremos en contacto contigo en la brevedad.</p>
   </div>
   <div v-else-if="estado == 2">
      <h2>¡Ha ocurrido un error!</h2>
      <p>Ha ocurrido un error al enviar el formulario. Puedes contactarnos mediante la información de contacto al final de la página.</p>
   </div>

   </div>
   <div v-else >
      <Loader :height="100" :width="100" />

   </div>
  </div>

  </div>


</transition>
<div v-if="showing" class="background-screen"></div>
</div>

</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import axios from 'axios'
import Loader from '@/components/Loader.vue';

export default {
  name: 'ModalMensaje',
  components: {
   Loader

  },
  props: {
    mensaje: String,
    titulo: String,
  },
  data(){
    return {
      showing:false,
      nombre:'',
      apellido:'',
      empresa:'',
      mensaje:'',
      email:'',
      loading:false,
      estado:0,
    }
  },
  methods:{
   send_contact(){
      this.loading = true;
      axios.post(
/*          'http://localhost:8000/api/contacto_empresas', */
         'https://marketplace.clinicgo.cl/api/contacto_empresas',
      {
         nombre:this.nombre,
      apellido:this.apellido,
      empresa:this.empresa,
      mensaje:this.mensaje,
      email:this.email,
   })
      .then(respuesta => {
         this.loading = false;
         this.estado = 1
      })
      .catch(error =>{
        this.loading = false;
        this.estado = 2;
      }) 
   },
   send_to(route){      
      document.body.style.position = '';
      document.body.style.top = '';
      this.$router.push({path:route})
      this.hide();
    },
    show(){
      this.showing = true;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
    },
    hide(){
      this.showing = false;
      document.body.style.position = '';
      document.body.style.top = '';
      this.estado = 0;
    }


  },
  mounted(){


  },
  computed:{
   ...mapGetters([
    'prestador',
    'comunas',
    'comunas_usadas',
    'categorias',
    'costo_servicio_pendiente',
    'examenes',
    'prestador_id',
    'categorias_selected',
    'prestador',
    ]),

  },
  watch:{
  
  },
}
</script>


<style scoped>
/* style.css */
.contact-form-container {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    max-width: 460px; /* Adjust as necessary for the modal */
}

.form-group {
    margin-bottom: 10px;
    text-align: left;

}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
    max-width: Calc(100vw - 60px);
    width: Calc(100% - 20px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
}

.form-group textarea {
    resize: vertical;
    height: 80px; /* Fixed height to make form compact */
}

button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px; /* Adjust spacing as necessary */
}

/* New styles for row layout */
.form-row {
    display: flex;
    justify-content: space-between;
    max-width: 100%;

}

.form-group.half {
   /*  width: 48%; */ /* Adjust based on gutter */
    width: calc(50% - 15px);
}

/* Responsive adjustment */
@media (max-width: 600px) {
   .contact-form-container{
      padding-top:40px;
   }
    .form-row {
        flex-direction: column;
    }
    .form-group.half {
        width: 100%;
    }
}
.button-modal-m{
   padding:10px 40px;
   border: 2px solid rgba(0, 0, 0, 1);
   color: rgba(0, 0, 0, 1);
   font-weight: 600;
   display: inline-block;
   width: 300px;
   margin:10px;
   border-radius: 3px;
   cursor: pointer;
}
.button-modal-m:hover{
   background-color: rgba(0, 0, 0, 1);
   transition: 0.3s;
   color:white;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px 3px;
}
.background-screen{
  height: 100vh;
  width: 100vw;
  position: fixed;
 
  backdrop-filter: blur(2px);
  z-index: 9999;

}
.titulo1{
  margin-bottom:30px;
  color: rgba(0, 0, 0, 1) !important;
}
.texto{
  text-align: center;
  padding: 0px 10px;
}
.main-m{

}
.greeny{
  color:#FF8C00;
  cursor:pointer;
  text-decoration: underline;
}
.subtitulo2{
  color:#FF8C00;
}
#modal-receta-m{
   transition: 0.5s;
   position:fixed;
 
   overflow-y:auto; 
   border-radius:10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:10px; 
   background:white; 
   top: 50%;
   left:0px;
    transform: translateY(-50%);
  
   width:550px;
   max-width:90%;
   margin: 0px auto; 
   right: 0;
   opacity:1;
   z-index:99999;
}
.flaco{
   width:600px !important;
   left: 10% !important;
   transform: translate(-10%, -50%) !important;
}
#modal-receta-m h2{
     text-align: center;
     font-weight: bold;
     color:rgba(0,0,0,0.6);
     margin-top:20px;
}
#modal-receta-m h4{
  text-align: center;
  color:rgb(0, 0, 0) !important;
  margin-bottom:25px;
  margin-top:15px;
}
#modal-receta-m h5{
  text-align: center;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.3) !important;
 
}
.cont{
  text-align: center;
  vertical-align: middle;
  position:absolute;
   width:Calc(100% - 40px);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
#modal-receta-m .icon{
   height:30px;
   width:27px;
   position: absolute;
   color:white;
   top:58%;
   left:50%;
   transform: translate(-50%, -50%);
}
#modal-receta-m .contenedor-icono{
   cursor:pointer;
   position:absolute;
   right:20px;
   top:20px;
   height:40px;
   width:40px;
   background-color: rgba(0, 0, 0, 1);
   border-radius:50px;
   z-index: 100001;
}
#modal-receta-m .subtitulo{
   text-align: center;
   font-size:22px;
   margin-bottom:40px;
   margin-top:20px;
   color:rgb(181, 181, 181);
   font-weight:350;
}
#modal-receta-m .input-wrapper{
   padding:0px 5px;
}
#modal-receta-m .btn-examinar{
   background-color:#FF8C00;
   padding:8px 20px;
   font-size:14px;
   font-weight:600;
   width:200px;
   max-width:Calc(100vw - 80px);
   color:white !important;
   outline: none;
   margin-top:20px;
}
#modal-receta-m .draggable-container{
   padding: 15px 5px 20px 5px;
   max-width: 600px;
   margin: 0 auto;
   background-color: rgb(247, 247, 247);
   border-style:dashed;
   border: 2px dashed rgb(200, 200, 200);
   border-radius:5px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white;
   z-index: 999;
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 10px); 
   border: 1px solid rgb(200, 200, 200);
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:20px;
}
#modal-receta-m .receta-label-mobile{
   display: none;
}
#modal-receta-m .btn-enviar{
   margin:0 auto; 
   cursor:pointer; 
   background-color:#1485e7;
   width: 100%;
   max-width: Calc(100vw - 60px);
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}

#modal-receta-m .upload-img{
   height:70px;
   width:70px;
   margin:0 auto; 
   margin-bottom:40px;
   margin-top:30px;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:50%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding-right:7px;
}
#modal-receta-m .comuna{
   position:relative;
}
#modal-receta-m .input-container{
   max-width:610px;
   margin: 0 auto;
}
@media only screen and (max-width: 1000px) {

#modal-receta-m .btn-examinar{
   padding:10px 20px;
   font-size:18px;
   font-weight:600;
   width:300px;
   max-width:Calc(100vw - 80px);
   color:white !important;
}
#modal-receta-m .receta-label-mobile{
   display: inline-block;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:100%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding:5px 20px;
 color:grey;
 
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:30px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white; 
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 40px); 
}
#modal-receta-m .receta-label-desk{
   display: none;
}
#modal-receta-m .draggable-container{
   border:none;
   background: none;
}
#modal-receta-m .contenido{
   z-index: 999999;
   position:relative;
      overflow-y:scroll !important;
   min-height:Calc(100vh);
   -webkit-overflow-scrolling: touch;
}
#modal-receta-m{
   
   position:fixed !important;
   height:Calc(100vh) !important;
   border-radius:0px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:0px; 
   background:white; 
   top:0px !important;
   width: 100vw !important;
   transform: none !important;
   max-width:100%;
   max-height: 100%;
   margin: 0px auto !important; 
   left: 0px !important;
   right: 0px !important;
   opacity:1;
   z-index:90001;
}
.primary{
   display: block;
}
.texto{
  text-align: center;
}
}

.zoom-m-enter-active,
.zoom-m-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: zoom-m;
}

.zoom-m-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-m {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
