<template>

  <router-view/>
</template>
<script>


</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&ampdisplay=swap');
html {
  scroll-behavior: smooth;
}
#opiniones, #faq, #funcionalidades, #quienes{
  scroll-margin-top: 120px;
  
}
#app {
  
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.hero-title {
    background: linear-gradient(to right, #1486e7 0%, #5a4aff 47.92%, #69bfff 100%);
    /* background: linear-gradient(to right, #1486e7 0%, white 47.92%, #69bfff 100%); */
    /* background: linear-gradient(to right, #28c76f 0%, #5a4aff 47.92%, #ff3739 100%); */
    background-size: 100% auto;
    color: #5d596c;
    background-clip: text;
    line-height: 1.2;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 2s ease-in-out infinite alternate;
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
    display: inline-block;
    max-width: 600px;

}
.btn-main{
  border:none;
  background-color: #1486e7;
  color: #fff;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer !important;
}
.label{
  background-color: #1485e742;
  color: #1486e7;
  border-radius: 5px;
  padding: 4px 10px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}

body {
    
    margin: 0;
    padding: 0;
}

</style>
